:root {
    --grid-gutter-x: 1.6rem;
}

.layout-with-centered-content {
    display: grid;
    grid-template: 'left main right';
    grid-template-rows: auto;
    grid-template-columns: 1fr 100% 1fr;
}

.main-container,
.main-container-fluid,
.fullscreen-component-content {
    padding-right: var(--grid-gutter-x);
    padding-left: var(--grid-gutter-x);
}

.main-container,
.main-container-fluid {
    position: relative;
}

.main-container {
    grid-column: main;
}

.main-container-fluid,
.main-container-cover {
    grid-column: left / right;
}

@media (min-width: 576px) {
    .layout-with-centered-content {
        --grid-gutter-x: 3.2rem;

        grid-template-columns: 1fr 540px 1fr;
    }

    .main-container,
    .main-container-fluid,
    .fullscreen-component {
        padding-right: calc(var(--grid-gutter-x) * 0.5);
        padding-left: calc(var(--grid-gutter-x) * 0.5);
    }
}

@media (min-width: 768px) {
    .layout-with-centered-content {
        grid-template-columns: 1fr 720px 1fr;
    }
}

@media (min-width: 992px) {
    .layout-with-centered-content {
        grid-template-columns: 1fr 960px 1fr;
    }
}

@media (min-width: 1200px) {
    .layout-with-centered-content {
        grid-template-columns: 1fr 1140px 1fr;
    }
}

@media (min-width: 1400px) {
    .layout-with-centered-content {
        grid-template-columns: 1fr 1320px 1fr;
    }
}
